const FirebaseConfig = {
  apiKey: "AIzaSyA6lDjYvQObJW_63KWXZo23FNKdDgCNRZ0",
  authDomain: "amar-ai.firebaseapp.com",
  projectId: "amar-ai",
  storageBucket: "amar-ai.appspot.com",
  messagingSenderId: "449181557158",
  appId: "1:449181557158:web:dd215c2ff0e2775f09af67",
  measurementId: "G-K883MWGQFV",
};

export default FirebaseConfig;
